import logo from '../assets/logo.png'
import {Box} from "@mui/material";

export default function Footer() {

    return (
        <Box borderTop="1px solid rgba(0, 0, 0, 0.12)" sx={{padding: '16px'}}>
            <img style={{height: '50px', float: 'right'}} src={logo} alt={'Hyperion logo'}/>
        </Box>
    )
}
