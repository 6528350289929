import {useFormDispatch, useForm} from "../reducers";
import parse from "html-react-parser";
import {DataStore} from "@aws-amplify/datastore";
import {Submissions} from "../models";
import {DataGrid} from "@mui/x-data-grid";
import {Box} from "@mui/material";
import {v4 as uuidv4} from "uuid";

export default function SubmissionList(props) {
    const dispatch = useFormDispatch();
    const {form, currentUser, submissions, pageNumber} = useForm();
    const controlAttributes = JSON.parse(props.control.attributes);
    const defaultData = controlAttributes.defaultData;

    // set the data
    let rows = submissions.map((submission) => {
        let rowData = {id: submission.id};
        controlAttributes.fields.forEach((fieldPath) => {
            // Split the field path and reduce to get the nested value
            const fieldParts = fieldPath.split('.');
            let currentValue = submission;
            for (let part of fieldParts) {
                currentValue = currentValue && currentValue[part] ? currentValue[part] : null;
                if (currentValue === null) {
                    break;
                }
            }
            // Replace dots with underscores in field keys for DataGrid compatibility
            rowData[fieldPath.replace(/\./g, '_')] = currentValue;
        });
        return rowData;
    });

    // get the field widths
    let widths = [];
    rows.forEach((r, i) => {
        widths[i] = [];
        Object.keys(r).forEach((f) => {
            let l;
            switch (typeof r[f]) {
                case "string":
                    l = r[f].length;
                    break;
                case "boolean":
                    l = 5;
                    break;
                case "number":
                    l = r[f].toString().length;
                    break;
                default:
                    l = 0;
            }
            widths[i].push(l);
        });
    });

    // find the largest width for each column
    let maxWidths = [];
    if (rows.length) {
        try {
            maxWidths = widths[0].map((_, index) =>
                Math.max(...widths.map((subArray) => subArray[index]))
            );
        } catch (e) {
            console.log(e);
        }
        maxWidths.shift();
    }

    // set the columns
    /*      let cols = [];
          controlAttributes.fields.forEach((f, i) => {
            cols.push({ field: f, headerName: null, flex: maxWidths[i] });
          });*/

    let cols = controlAttributes.fields.map((fieldPath, i) => {
        // Convert nested paths to DataGrid-compatible field keys
        const dataGridFieldKey = fieldPath.replace(/\./g, '_');

        // Set up column definitions, using the adjusted field keys
        return {
            field: dataGridFieldKey,
            // headerName: fieldPath.split('.').pop(), // Optional: Use the last segment as a header name
            flex: maxWidths[i] || 1
        };
    });

    async function newSubmission() {
        const team = [];
        const newId = uuidv4();
        await DataStore.save(
            new Submissions({
                meta: {
                    page: 0,
                    owner: JSON.stringify({
                        name: currentUser?.familyName + ", " + currentUser?.givenName,
                        userId: currentUser?.id,
                    }),
                    creator: JSON.stringify({
                        name: currentUser?.familyName + ", " + currentUser?.givenName,
                        userId: currentUser?.id,
                    }),
                    team: team,
                },
                id: newId,
                data: defaultData,
                formId: form.id,
                formToken: form.token,
                token: "",
                projectId: form.projectId,
                userId: currentUser?.id,
            })
        )
            .then(() => {
                dispatch({type: "LOAD_SUBMISSIONS", value: submissions});
            })
            .then(() => {
                // load(newId)
            })
            .catch(e => {
                console.log(e)
            });
    }

    function load(id) {
        dispatch({type: "SET_SUBMISSION_ID", value: id});
        dispatch({type: "SET_PAGE_NUMBER", value: pageNumber + 1});
    }

    return (
        <>
            <p>{parse(props.control.content)}</p>
            {rows && rows.length > 0 && (
                <Box mb={2}>
                    <DataGrid
                        autoWidth
                        rows={rows}
                        columns={cols}
                        columnHeaderHeight={0}
                        onRowClick={(rowParams) => load(rowParams.id)}
                        hideFooter={true}
                    />
                </Box>)}
            {rows.length < (controlAttributes.maxSubmissions ?? 1) && (
                <div key={props.currentKey + "1"}>
                    <button
                        onClick={() => {
                            newSubmission();
                        }}
                    >
                        {controlAttributes?.addNewLabel}
                    </button>
                </div>
            )}
        </>
    );
}
