import parse from "html-react-parser";
import {Box} from "@mui/material";

export default function Summary(props) {
    const submission = props.submission;
    const attributes = JSON.parse(props.control.attributes);
    // console.log(submission, attributes)

    let summaryText = ""
    attributes.fields.forEach((f) => {
        try {
            summaryText += "<p>" + submission.data[f] +"</p>" || null;
        } catch (e) {
            console.log(e);
        }
    });

    return (
        <>
            <p>{parse(props.control.content)}</p>
            <Box mb={2}>
                {parse(summaryText)}
            </Box>
        </>
    );
}
