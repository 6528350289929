import {useEffect} from 'react';
import {useForm} from "../reducers";
import {Hub} from "aws-amplify/utils";
import {generateClient} from "aws-amplify/api";
import {getTrackingNumber, sendEmail} from "../graphql/mutations";

const EventsComponent = () => {
    const {submissionId, complete} = useForm();
    const {currentUser} = useForm();

    // let submission = submissions.find((item) => item.id === submissionId);

    const client = generateClient();

    const sendEmailHandler = () => {
        console.log('send email');
        try {
            const emailDetails = {
                email: currentUser.email,
                subject: "Your application to ACA 2024",
                message:"Thank you for your application to the Advocacy-for-Cure Academy 2024."
            };
            console.log(emailDetails)
            client.graphql({
                query: sendEmail,
                variables: emailDetails,
                // authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
                .then(r => {
                    console.log(r)
                })
                .catch(e => {
                    console.log(e)
                })
            // console.log('Email sent:', result);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    const trackingNumberHandler = () => {
        console.log('get tracking number');
        const fetchData = async () => {
            try {
                const result = await client.graphql({
                    query: getTrackingNumber,
                    variables: {id: submissionId}
                });
                console.log(
                    "result.data.getTrackingNumber ",
                    result.data.getTrackingNumber
                );
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    };

    // Master event listener setup
    useEffect(() => {
        console.log('set up event handling')
        const listener = Hub.listen("events", async (data) => {
            const {payload} = data;
            switch (payload.event) {
                case 'complete':
                    console.log(complete)
                    console.log('onComplete')
                    sendEmailHandler();
                    trackingNumberHandler();

                    break;
                default:
                    console.log('Unhandled event:', data.event);
            }
        });

        return () => {
            listener();
        };
    }, []);

    return null;
};

export default EventsComponent;
