import { useFormDispatch, useForm } from "../reducers";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { ArrowCircleRight, TaskAlt, NavigateNext, NavigateBefore, Cancel } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Hub } from "aws-amplify/utils";

/**
 * @return {null}
 */
export default function Navbar() {
    const dispatch = useFormDispatch();
    const { form, pageNumber, translateUI, numberOfInvalidControls } = useForm();
    const { pages = [] } = form;
    const { navigation = [] } = pages[pageNumber] || {};
    const theme = useTheme();

    if (!navigation) {
        return null
    }

    const handleChange = (event, newValue) => {
        // console.log(newValue);
        dispatch({ type: "SET_PAGE_NUMBER", value: newValue.newPageNumber });
        if (newValue.action) {
            dispatch({ type: newValue.action, value: newValue.actionValue });
        }
        if (newValue.buttonEvent) {
            Hub.dispatch('events', { event: newValue.buttonEvent });
        }
    };

    return (
        <Box borderTop="1px solid rgba(0, 0, 0, 0.12)">
            <BottomNavigation
                showLabels
                onChange={handleChange}
                sx={{ backgroundColor: "#f0f0f0", padding: "16px" }}
            >
                {navigation.map((nav, index) => {
                    const { icon, buttonColor, newPageNumber, action, buttonEvent, actionValue } = getNavProps(nav, theme, pageNumber, numberOfInvalidControls);
                    const isButtonDisabled = nav.type === "NEXT" && numberOfInvalidControls;

                    return (
                        <BottomNavigationAction
                            key={index}
                            label={translateUI(nav.label)}
                            icon={icon}
                            sx={{
                                color: isButtonDisabled ? "#afafaf" : buttonColor,
                                "&.Mui-disabled": { color: "#afafaf" },
                                "& .MuiSvgIcon-root": { fontSize: "30pt" },
                            }}
                            disabled={isButtonDisabled}
                            value={{ newPageNumber, action, actionValue, buttonEvent }}
                        />
                    );
                })}
            </BottomNavigation>
        </Box>
    );
}

function getNavProps(nav, theme, pageNumber) {
    let icon, buttonColor = "inherit", newPageNumber, action, actionValue, buttonEvent;

    switch (nav.type) {
        case "NEXT":
            icon = <NavigateNext />;
            buttonColor = theme.palette.info.dark;
            newPageNumber = pageNumber + 1;
            break;
        case "PREVIOUS":
            icon = <NavigateBefore />;
            newPageNumber = pageNumber - 1;
            break;
        case "CANCEL":
            icon = <Cancel />;
            buttonColor = theme.palette.error.main;
            newPageNumber = 0;
            break;
        case "SUBMIT":
            icon = <ArrowCircleRight />;
            buttonColor = theme.palette.warning.dark;
            newPageNumber = 0;
            break;
        case "DONE":
            icon = <TaskAlt />;
            buttonColor = theme.palette.warning.dark;
            newPageNumber = 0;
            action = 'SET_SUBMISSION_COMPLETE';
            buttonEvent = 'complete';
            actionValue = true;
            break;
        default:
            icon = null;
    }

    return { icon, buttonColor, newPageNumber, action, actionValue, buttonEvent };
}
