/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccounts = /* GraphQL */ `
  mutation CreateAccounts(
    $condition: ModelAccountsConditionInput
    $input: CreateAccountsInput!
  ) {
    createAccounts(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      abbreviation
      contacts
      createdAt
      id
      name
      token
      updatedAt
      __typename
    }
  }
`;
export const createForms = /* GraphQL */ `
  mutation CreateForms(
    $condition: ModelFormsConditionInput
    $input: CreateFormsInput!
  ) {
    createForms(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      id
      meta
      pages {
        controls {
          action
          attributes
          content
          errorMessage
          flags {
            isEnabled
            isValid
            isVisible
            __typename
          }
          key
          label
          options
          policies {
            flag
            __typename
          }
          required
          type
          __typename
        }
        footer
        header
        navigation {
          active
          label
          type
          __typename
        }
        title
        __typename
      }
      projectId
      token
      trackingCounter
      updatedAt
      __typename
    }
  }
`;
export const createProjects = /* GraphQL */ `
  mutation CreateProjects(
    $condition: ModelProjectsConditionInput
    $input: CreateProjectsInput!
  ) {
    createProjects(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      abbreviation
      accountId
      code
      contacts
      createdAt
      id
      name
      theme
      timetable
      token
      updatedAt
      __typename
    }
  }
`;
export const createSubmissions = /* GraphQL */ `
  mutation CreateSubmissions(
    $condition: ModelSubmissionsConditionInput
    $input: CreateSubmissionsInput!
  ) {
    createSubmissions(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      checked
      createdAt
      data
      files {
        dataKey
        fileKey
        hash
        id
        name
        size
        uploaded
        __typename
      }
      formId
      formToken
      id
      meta {
        complete
        creator
        owner
        page
        percentComplete
        team {
          email
          familyName
          fields
          givenName
          readOnly
          userId
          __typename
        }
        trackingNumber
        __typename
      }
      owner
      token
      updatedAt
      userId
      __typename
    }
  }
`;
export const createTokens = /* GraphQL */ `
  mutation CreateTokens(
    $condition: ModelTokensConditionInput
    $input: CreateTokensInput!
  ) {
    createTokens(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      id
      token
      type
      updatedAt
      __typename
    }
  }
`;
export const createUsers = /* GraphQL */ `
  mutation CreateUsers(
    $condition: ModelUsersConditionInput
    $input: CreateUsersInput!
  ) {
    createUsers(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      currentState
      email
      familyName
      forms {
        currentPage
        editors
        formId
        __typename
      }
      givenName
      id
      language
      owner
      projectAdmin
      updatedAt
      __typename
    }
  }
`;
export const deleteAccounts = /* GraphQL */ `
  mutation DeleteAccounts(
    $condition: ModelAccountsConditionInput
    $input: DeleteAccountsInput!
  ) {
    deleteAccounts(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      abbreviation
      contacts
      createdAt
      id
      name
      token
      updatedAt
      __typename
    }
  }
`;
export const deleteForms = /* GraphQL */ `
  mutation DeleteForms(
    $condition: ModelFormsConditionInput
    $input: DeleteFormsInput!
  ) {
    deleteForms(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      id
      meta
      pages {
        controls {
          action
          attributes
          content
          errorMessage
          flags {
            isEnabled
            isValid
            isVisible
            __typename
          }
          key
          label
          options
          policies {
            flag
            __typename
          }
          required
          type
          __typename
        }
        footer
        header
        navigation {
          active
          label
          type
          __typename
        }
        title
        __typename
      }
      projectId
      token
      trackingCounter
      updatedAt
      __typename
    }
  }
`;
export const deleteProjects = /* GraphQL */ `
  mutation DeleteProjects(
    $condition: ModelProjectsConditionInput
    $input: DeleteProjectsInput!
  ) {
    deleteProjects(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      abbreviation
      accountId
      code
      contacts
      createdAt
      id
      name
      theme
      timetable
      token
      updatedAt
      __typename
    }
  }
`;
export const deleteSubmissions = /* GraphQL */ `
  mutation DeleteSubmissions(
    $condition: ModelSubmissionsConditionInput
    $input: DeleteSubmissionsInput!
  ) {
    deleteSubmissions(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      checked
      createdAt
      data
      files {
        dataKey
        fileKey
        hash
        id
        name
        size
        uploaded
        __typename
      }
      formId
      formToken
      id
      meta {
        complete
        creator
        owner
        page
        percentComplete
        team {
          email
          familyName
          fields
          givenName
          readOnly
          userId
          __typename
        }
        trackingNumber
        __typename
      }
      owner
      token
      updatedAt
      userId
      __typename
    }
  }
`;
export const deleteTokens = /* GraphQL */ `
  mutation DeleteTokens(
    $condition: ModelTokensConditionInput
    $input: DeleteTokensInput!
  ) {
    deleteTokens(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      id
      token
      type
      updatedAt
      __typename
    }
  }
`;
export const deleteUsers = /* GraphQL */ `
  mutation DeleteUsers(
    $condition: ModelUsersConditionInput
    $input: DeleteUsersInput!
  ) {
    deleteUsers(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      currentState
      email
      familyName
      forms {
        currentPage
        editors
        formId
        __typename
      }
      givenName
      id
      language
      owner
      projectAdmin
      updatedAt
      __typename
    }
  }
`;
export const getTrackingNumber = /* GraphQL */ `
  mutation GetTrackingNumber($id: String!) {
    getTrackingNumber(id: $id)
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($email: String!, $message: String!, $subject: String!) {
    sendEmail(email: $email, message: $message, subject: $subject) {
      message
      success
      __typename
    }
  }
`;
export const updateAccounts = /* GraphQL */ `
  mutation UpdateAccounts(
    $condition: ModelAccountsConditionInput
    $input: UpdateAccountsInput!
  ) {
    updateAccounts(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      abbreviation
      contacts
      createdAt
      id
      name
      token
      updatedAt
      __typename
    }
  }
`;
export const updateForms = /* GraphQL */ `
  mutation UpdateForms(
    $condition: ModelFormsConditionInput
    $input: UpdateFormsInput!
  ) {
    updateForms(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      id
      meta
      pages {
        controls {
          action
          attributes
          content
          errorMessage
          flags {
            isEnabled
            isValid
            isVisible
            __typename
          }
          key
          label
          options
          policies {
            flag
            __typename
          }
          required
          type
          __typename
        }
        footer
        header
        navigation {
          active
          label
          type
          __typename
        }
        title
        __typename
      }
      projectId
      token
      trackingCounter
      updatedAt
      __typename
    }
  }
`;
export const updateProjects = /* GraphQL */ `
  mutation UpdateProjects(
    $condition: ModelProjectsConditionInput
    $input: UpdateProjectsInput!
  ) {
    updateProjects(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      abbreviation
      accountId
      code
      contacts
      createdAt
      id
      name
      theme
      timetable
      token
      updatedAt
      __typename
    }
  }
`;
export const updateSubmissions = /* GraphQL */ `
  mutation UpdateSubmissions(
    $condition: ModelSubmissionsConditionInput
    $input: UpdateSubmissionsInput!
  ) {
    updateSubmissions(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      checked
      createdAt
      data
      files {
        dataKey
        fileKey
        hash
        id
        name
        size
        uploaded
        __typename
      }
      formId
      formToken
      id
      meta {
        complete
        creator
        owner
        page
        percentComplete
        team {
          email
          familyName
          fields
          givenName
          readOnly
          userId
          __typename
        }
        trackingNumber
        __typename
      }
      owner
      token
      updatedAt
      userId
      __typename
    }
  }
`;
export const updateTokens = /* GraphQL */ `
  mutation UpdateTokens(
    $condition: ModelTokensConditionInput
    $input: UpdateTokensInput!
  ) {
    updateTokens(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      id
      token
      type
      updatedAt
      __typename
    }
  }
`;
export const updateUsers = /* GraphQL */ `
  mutation UpdateUsers(
    $condition: ModelUsersConditionInput
    $input: UpdateUsersInput!
  ) {
    updateUsers(condition: $condition, input: $input) {
      _deleted
      _lastChangedAt
      _version
      createdAt
      currentState
      email
      familyName
      forms {
        currentPage
        editors
        formId
        __typename
      }
      givenName
      id
      language
      owner
      projectAdmin
      updatedAt
      __typename
    }
  }
`;
