import React, {useState} from 'react';
import {Box, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import {Logout} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import parse from 'html-react-parser';
import {useForm, useFormDispatch} from '../reducers';

const languageOptions = [
    { code: 'en', name: 'English' },
    { code: 'fr', name: 'French' },
    { code: 'es', name: 'Spanish' }]

export default function Header(props) {
    const {form, language} = useForm();
    const dispatch = useFormDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleLanguageMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageSelect = (code) => {
        dispatch({type:'SET_LANGUAGE', value: code || 'en'});
        handleLanguageMenuClose();
    };

    return (
        <Box borderBottom="1px solid rgba(0, 0, 0, 0.12)">
            <Toolbar>
                <Typography variant="h6" sx={{flexGrow: 1}}>
                    {parse(form.meta.name)}
                </Typography>
                <IconButton color="inherit" onClick={handleLanguageMenuOpen}>
                    {/*<Language/>*/}
                    {language}
                    <ArrowDropDownIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleLanguageMenuClose}
                >
                    {languageOptions.map(option => (
                        <MenuItem key={option.code} onClick={() => handleLanguageSelect(option.code)}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Menu>
                <IconButton color="inherit" onClick={() => props.logout()}>
                    <Logout/>
                </IconButton>
            </Toolbar>
        </Box>
    )
}
