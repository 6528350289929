// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ControlTypes = {
  "AFFILIATIONS": "AFFILIATIONS",
  "AUTHORS": "AUTHORS",
  "BUTTON": "BUTTON",
  "CHECKBOX": "CHECKBOX",
  "DATETIME": "DATETIME",
  "FILE": "FILE",
  "INFORMATION": "INFORMATION",
  "MESSAGE": "MESSAGE",
  "RADIO": "RADIO",
  "RATING": "RATING",
  "SELECT": "SELECT",
  "SLIDER": "SLIDER",
  "SPONSOR": "SPONSOR",
  "SUBMISSIONLIST": "SUBMISSIONLIST",
  "SUMMARY": "SUMMARY",
  "SWITCH": "SWITCH",
  "TABLE": "TABLE",
  "TEXT": "TEXT",
  "TOGGLE": "TOGGLE"
};

const ModelAttributeTypes = {
  "NULL": "_null",
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet"
};

const ModelSortDirection = {
  "ASC": "ASC",
  "DESC": "DESC"
};

const NavButtonTypes = {
  "CANCEL": "CANCEL",
  "DONE": "DONE",
  "GOTO": "GOTO",
  "NEXT": "NEXT",
  "PREVIOUS": "PREVIOUS",
  "SUBMIT": "SUBMIT"
};

const { Accounts, Controls, EmailResponse, Files, Flags, Forms, ModelAccountsConnection, ModelFormsConnection, ModelProjectsConnection, ModelSubmissionsConnection, ModelTokensConnection, ModelUsersConnection, NavButtons, Pages, Policies, Projects, Rules, SubmissionMetadata, Submissions, TeamMember, Tokens, UserForms, Users } = initSchema(schema);

export {
  ControlTypes,
  ModelAttributeTypes,
  ModelSortDirection,
  NavButtonTypes,
  Accounts,
  Controls,
  EmailResponse,
  Files,
  Flags,
  Forms,
  ModelAccountsConnection,
  ModelFormsConnection,
  ModelProjectsConnection,
  ModelSubmissionsConnection,
  ModelTokensConnection,
  ModelUsersConnection,
  NavButtons,
  Pages,
  Policies,
  Projects,
  Rules,
  SubmissionMetadata,
  Submissions,
  TeamMember,
  Tokens,
  UserForms,
  Users
};