// https://unstats.un.org/unsd/methodology/m49/overview/

export const countryCodes = {
    "en-gb": [
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 15,
            "Sub_region": "Northern Africa",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Algeria",
            "Code": "DZA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 15,
            "Sub_region": "Northern Africa",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Egypt",
            "Code": "EGY"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 15,
            "Sub_region": "Northern Africa",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Libya",
            "Code": "LBY"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 15,
            "Sub_region": "Northern Africa",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Morocco",
            "Code": "MAR"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 15,
            "Sub_region": "Northern Africa",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Sudan",
            "Code": "SDN"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 15,
            "Sub_region": "Northern Africa",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Tunisia",
            "Code": "TUN"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 15,
            "Sub_region": "Northern Africa",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Western Sahara",
            "Code": "ESH"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "British Indian Ocean Territory",
            "Code": "IOT"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Burundi",
            "Code": "BDI"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Comoros",
            "Code": "COM"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Djibouti",
            "Code": "DJI"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Eritrea",
            "Code": "ERI"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Ethiopia",
            "Code": "ETH"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "French Southern Territories",
            "Code": "ATF"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Kenya",
            "Code": "KEN"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Madagascar",
            "Code": "MDG"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Malawi",
            "Code": "MWI"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Mauritius",
            "Code": "MUS"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Mayotte",
            "Code": "MYT"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Mozambique",
            "Code": "MOZ"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Réunion",
            "Code": "REU"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Rwanda",
            "Code": "RWA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Seychelles",
            "Code": "SYC"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Somalia",
            "Code": "SOM"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "South Sudan",
            "Code": "SSD"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Uganda",
            "Code": "UGA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "United Republic of Tanzania",
            "Code": "TZA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Zambia",
            "Code": "ZMB"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 14,
            "Intermediate_Region": "Eastern Africa",
            "Country": "Zimbabwe",
            "Code": "ZWE"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Angola",
            "Code": "AGO"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Cameroon",
            "Code": "CMR"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Central African Republic",
            "Code": "CAF"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Chad",
            "Code": "TCD"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Congo",
            "Code": "COG"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Democratic Republic of the Congo",
            "Code": "COD"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Equatorial Guinea",
            "Code": "GNQ"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Gabon",
            "Code": "GAB"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 17,
            "Intermediate_Region": "Middle Africa",
            "Country": "Sao Tome and Principe",
            "Code": "STP"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 18,
            "Intermediate_Region": "Southern Africa",
            "Country": "Botswana",
            "Code": "BWA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 18,
            "Intermediate_Region": "Southern Africa",
            "Country": "Eswatini",
            "Code": "SWZ"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 18,
            "Intermediate_Region": "Southern Africa",
            "Country": "Lesotho",
            "Code": "LSO"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 18,
            "Intermediate_Region": "Southern Africa",
            "Country": "Namibia",
            "Code": "NAM"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 18,
            "Intermediate_Region": "Southern Africa",
            "Country": "South Africa",
            "Code": "ZAF"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Benin",
            "Code": "BEN"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Burkina Faso",
            "Code": "BFA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Cabo Verde",
            "Code": "CPV"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Côte d’Ivoire",
            "Code": "CIV"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Gambia",
            "Code": "GMB"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Ghana",
            "Code": "GHA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Guinea",
            "Code": "GIN"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Guinea-Bissau",
            "Code": "GNB"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Liberia",
            "Code": "LBR"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Mali",
            "Code": "MLI"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Mauritania",
            "Code": "MRT"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Niger",
            "Code": "NER"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Nigeria",
            "Code": "NGA"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Saint Helena",
            "Code": "SHN"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Senegal",
            "Code": "SEN"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Sierra Leone",
            "Code": "SLE"
        },
        {
            "Region_Code": 2,
            "Region": "Africa",
            "Sub-region_Code": 202,
            "Sub_region": "Sub-Saharan Africa",
            "Intermediate_Region_Code": 11,
            "Intermediate_Region": "Western Africa",
            "Country": "Togo",
            "Code": "TGO"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Anguilla",
            "Code": "AIA"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Antigua and Barbuda",
            "Code": "ATG"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Aruba",
            "Code": "ABW"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Bahamas",
            "Code": "BHS"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Barbados",
            "Code": "BRB"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Bonaire Sint Eustatius and Saba",
            "Code": "BES"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "British Virgin Islands",
            "Code": "VGB"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Cayman Islands",
            "Code": "CYM"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Cuba",
            "Code": "CUB"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Curaçao",
            "Code": "CUW"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Dominica",
            "Code": "DMA"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Dominican Republic",
            "Code": "DOM"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Grenada",
            "Code": "GRD"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Guadeloupe",
            "Code": "GLP"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Haiti",
            "Code": "HTI"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Jamaica",
            "Code": "JAM"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Martinique",
            "Code": "MTQ"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Montserrat",
            "Code": "MSR"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Puerto Rico",
            "Code": "PRI"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Saint Barthélemy",
            "Code": "BLM"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Saint Kitts and Nevis",
            "Code": "KNA"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Saint Lucia",
            "Code": "LCA"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Saint Martin (French Part)",
            "Code": "MAF"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Saint Vincent and the Grenadines",
            "Code": "VCT"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Sint Maarten (Dutch part)",
            "Code": "SXM"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Trinidad and Tobago",
            "Code": "TTO"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "Turks and Caicos Islands",
            "Code": "TCA"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 29,
            "Intermediate_Region": "Caribbean",
            "Country": "United States Virgin Islands",
            "Code": "VIR"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "Belize",
            "Code": "BLZ"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "Costa Rica",
            "Code": "CRI"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "El Salvador",
            "Code": "SLV"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "Guatemala",
            "Code": "GTM"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "Honduras",
            "Code": "HND"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "Mexico",
            "Code": "MEX"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "Nicaragua",
            "Code": "NIC"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 13,
            "Intermediate_Region": "Central America",
            "Country": "Panama",
            "Code": "PAN"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Argentina",
            "Code": "ARG"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Bolivia (Plurinational State of)",
            "Code": "BOL"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Bouvet Island",
            "Code": "BVT"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Brazil",
            "Code": "BRA"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Chile",
            "Code": "CHL"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Colombia",
            "Code": "COL"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Ecuador",
            "Code": "ECU"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Falkland Islands (Malvinas)",
            "Code": "FLK"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "French Guiana",
            "Code": "GUF"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Guyana",
            "Code": "GUY"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Paraguay",
            "Code": "PRY"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Peru",
            "Code": "PER"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "South Georgia and the South Sandwich Islands",
            "Code": "SGS"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Suriname",
            "Code": "SUR"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Uruguay",
            "Code": "URY"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 419,
            "Sub_region": "Latin America and the Caribbean",
            "Intermediate_Region_Code": 5,
            "Intermediate_Region": "South America",
            "Country": "Venezuela (Bolivarian Republic of)",
            "Code": "VEN"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 21,
            "Sub_region": "Northern America",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Bermuda",
            "Code": "BMU"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 21,
            "Sub_region": "Northern America",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Canada",
            "Code": "CAN"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 21,
            "Sub_region": "Northern America",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Greenland",
            "Code": "GRL"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 21,
            "Sub_region": "Northern America",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Saint Pierre and Miquelon",
            "Code": "SPM"
        },
        {
            "Region_Code": 19,
            "Region": "Americas",
            "Sub-region_Code": 21,
            "Sub_region": "Northern America",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "United States of America",
            "Code": "USA"
        },
        {
            "Region_Code": null,
            "Region": "",
            "Sub-region_Code": null,
            "Sub_region": "",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Antarctica",
            "Code": "ATA"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 143,
            "Sub_region": "Central Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Kazakhstan",
            "Code": "KAZ"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 143,
            "Sub_region": "Central Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Kyrgyzstan",
            "Code": "KGZ"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 143,
            "Sub_region": "Central Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Tajikistan",
            "Code": "TJK"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 143,
            "Sub_region": "Central Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Turkmenistan",
            "Code": "TKM"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 143,
            "Sub_region": "Central Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Uzbekistan",
            "Code": "UZB"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 30,
            "Sub_region": "Eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "China",
            "Code": "CHN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 30,
            "Sub_region": "Eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "China: Hong Kong Special Administrative Region",
            "Code": "HKG"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 30,
            "Sub_region": "Eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "China: Macao Special Administrative Region",
            "Code": "MAC"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 30,
            "Sub_region": "Eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Democratic People's Republic of Korea",
            "Code": "PRK"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 30,
            "Sub_region": "Eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Japan",
            "Code": "JPN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 30,
            "Sub_region": "Eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Mongolia",
            "Code": "MNG"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 30,
            "Sub_region": "Eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Republic of Korea",
            "Code": "KOR"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Brunei Darussalam",
            "Code": "BRN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Cambodia",
            "Code": "KHM"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Indonesia",
            "Code": "IDN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Lao People's Democratic Republic",
            "Code": "LAO"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Malaysia",
            "Code": "MYS"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Myanmar",
            "Code": "MMR"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Philippines",
            "Code": "PHL"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Singapore",
            "Code": "SGP"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Thailand",
            "Code": "THA"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Timor-Leste",
            "Code": "TLS"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 35,
            "Sub_region": "South-eastern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Viet Nam",
            "Code": "VNM"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Afghanistan",
            "Code": "AFG"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Bangladesh",
            "Code": "BGD"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Bhutan",
            "Code": "BTN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "India",
            "Code": "IND"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Iran (Islamic Republic of)",
            "Code": "IRN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Maldives",
            "Code": "MDV"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Nepal",
            "Code": "NPL"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Pakistan",
            "Code": "PAK"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 34,
            "Sub_region": "Southern Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Sri Lanka",
            "Code": "LKA"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Armenia",
            "Code": "ARM"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Azerbaijan",
            "Code": "AZE"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Bahrain",
            "Code": "BHR"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Cyprus",
            "Code": "CYP"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Georgia",
            "Code": "GEO"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Iraq",
            "Code": "IRQ"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Israel",
            "Code": "ISR"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Jordan",
            "Code": "JOR"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Kuwait",
            "Code": "KWT"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Lebanon",
            "Code": "LBN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Oman",
            "Code": "OMN"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Qatar",
            "Code": "QAT"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Saudi Arabia",
            "Code": "SAU"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "State of Palestine",
            "Code": "PSE"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Syrian Arab Republic",
            "Code": "SYR"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Türkiye",
            "Code": "TUR"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "United Arab Emirates",
            "Code": "ARE"
        },
        {
            "Region_Code": 142,
            "Region": "Asia",
            "Sub-region_Code": 145,
            "Sub_region": "Western Asia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Yemen",
            "Code": "YEM"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Belarus",
            "Code": "BLR"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Bulgaria",
            "Code": "BGR"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Czechia",
            "Code": "CZE"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Hungary",
            "Code": "HUN"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Poland",
            "Code": "POL"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Republic of Moldova",
            "Code": "MDA"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Romania",
            "Code": "ROU"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Russian Federation",
            "Code": "RUS"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Slovakia",
            "Code": "SVK"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 151,
            "Sub_region": "Eastern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Ukraine",
            "Code": "UKR"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Åland Islands",
            "Code": "ALA"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": 830,
            "Intermediate_Region": "Channel Islands",
            "Country": "Guernsey",
            "Code": "GGY"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": 830,
            "Intermediate_Region": "Channel Islands",
            "Country": "Jersey",
            "Code": "JEY"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": 830,
            "Intermediate_Region": "Channel Islands",
            "Country": "Sark",
            "Code": ""
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Denmark",
            "Code": "DNK"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Estonia",
            "Code": "EST"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Faroe Islands",
            "Code": "FRO"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Finland",
            "Code": "FIN"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Iceland",
            "Code": "ISL"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Ireland",
            "Code": "IRL"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Isle of Man",
            "Code": "IMN"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Latvia",
            "Code": "LVA"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Lithuania",
            "Code": "LTU"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Norway",
            "Code": "NOR"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Svalbard and Jan Mayen Islands",
            "Code": "SJM"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Sweden",
            "Code": "SWE"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 154,
            "Sub_region": "Northern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "United Kingdom of Great Britain and Northern Ireland",
            "Code": "GBR"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Albania",
            "Code": "ALB"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Andorra",
            "Code": "AND"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Bosnia and Herzegovina",
            "Code": "BIH"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Croatia",
            "Code": "HRV"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Gibraltar",
            "Code": "GIB"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Greece",
            "Code": "GRC"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Holy See",
            "Code": "VAT"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Italy",
            "Code": "ITA"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Malta",
            "Code": "MLT"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Montenegro",
            "Code": "MNE"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "North Macedonia",
            "Code": "MKD"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Portugal",
            "Code": "PRT"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "San Marino",
            "Code": "SMR"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Serbia",
            "Code": "SRB"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Slovenia",
            "Code": "SVN"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 39,
            "Sub_region": "Southern Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Spain",
            "Code": "ESP"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Austria",
            "Code": "AUT"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Belgium",
            "Code": "BEL"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "France",
            "Code": "FRA"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Germany",
            "Code": "DEU"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Liechtenstein",
            "Code": "LIE"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Luxembourg",
            "Code": "LUX"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Monaco",
            "Code": "MCO"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Netherlands (Kingdom of the)",
            "Code": "NLD"
        },
        {
            "Region_Code": 150,
            "Region": "Europe",
            "Sub-region_Code": 155,
            "Sub_region": "Western Europe",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Switzerland",
            "Code": "CHE"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 53,
            "Sub_region": "Australia and New Zealand",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Australia",
            "Code": "AUS"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 53,
            "Sub_region": "Australia and New Zealand",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Christmas Island",
            "Code": "CXR"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 53,
            "Sub_region": "Australia and New Zealand",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Cocos (Keeling) Islands",
            "Code": "CCK"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 53,
            "Sub_region": "Australia and New Zealand",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Heard Island and McDonald Islands",
            "Code": "HMD"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 53,
            "Sub_region": "Australia and New Zealand",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "New Zealand",
            "Code": "NZL"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 53,
            "Sub_region": "Australia and New Zealand",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Norfolk Island",
            "Code": "NFK"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 54,
            "Sub_region": "Melanesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Fiji",
            "Code": "FJI"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 54,
            "Sub_region": "Melanesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "New Caledonia",
            "Code": "NCL"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 54,
            "Sub_region": "Melanesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Papua New Guinea",
            "Code": "PNG"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 54,
            "Sub_region": "Melanesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Solomon Islands",
            "Code": "SLB"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 54,
            "Sub_region": "Melanesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Vanuatu",
            "Code": "VUT"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Guam",
            "Code": "GUM"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Kiribati",
            "Code": "KIR"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Marshall Islands",
            "Code": "MHL"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Micronesia (Federated States of)",
            "Code": "FSM"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Nauru",
            "Code": "NRU"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Northern Mariana Islands",
            "Code": "MNP"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Palau",
            "Code": "PLW"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 57,
            "Sub_region": "Micronesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "United States Minor Outlying Islands",
            "Code": "UMI"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "American Samoa",
            "Code": "ASM"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Cook Islands",
            "Code": "COK"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "French Polynesia",
            "Code": "PYF"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Niue",
            "Code": "NIU"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Pitcairn",
            "Code": "PCN"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Samoa",
            "Code": "WSM"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Tokelau",
            "Code": "TKL"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Tonga",
            "Code": "TON"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Tuvalu",
            "Code": "TUV"
        },
        {
            "Region_Code": 9,
            "Region": "Oceania",
            "Sub-region_Code": 61,
            "Sub_region": "Polynesia",
            "Intermediate_Region_Code": null,
            "Intermediate_Region": "",
            "Country": "Wallis and Futuna Islands",
            "Code": "WLF"
        }
    ]
};

export const IASworkCountryCodes = {
    "en-gb": [
        {
            "Country": "Afghanistan",
            "Code": "AF"
        },
        {
            "Country": "Albania",
            "Code": "AL"
        },
        {
            "Country": "Algeria",
            "Code": "DZ"
        },
        {
            "Country": "American Samoa",
            "Code": "AS"
        },
        {
            "Country": "Angola",
            "Code": "AO"
        },
        {
            "Country": "Argentina",
            "Code": "AR"
        },
        {
            "Country": "Armenia",
            "Code": "AM"
        },
        {
            "Country": "Azerbaijan",
            "Code": "AZ"
        },
        {
            "Country": "Bangladesh",
            "Code": "BD"
        },
        {
            "Country": "Belarus",
            "Code": "BY"
        },
        {
            "Country": "Belize",
            "Code": "BZ"
        },
        {
            "Country": "Benin",
            "Code": "BJ"
        },
        {
            "Country": "Bhutan",
            "Code": "BT"
        },
        {
            "Country": "Bolivia",
            "Code": "BO"
        },
        {
            "Country": "Bosnia and Herzegovina",
            "Code": "BA"
        },
        {
            "Country": "Botswana",
            "Code": "BW"
        },
        {
            "Country": "Brazil",
            "Code": "BR"
        },
        {
            "Country": "Bulgaria",
            "Code": "BG"
        },
        {
            "Country": "Burkina Faso",
            "Code": "BF"
        },
        {
            "Country": "Burundi",
            "Code": "BI"
        },
        {
            "Country": "Cambodia",
            "Code": "KH"
        },
        {
            "Country": "Cameroon",
            "Code": "CM"
        },
        {
            "Country": "Cape Verde",
            "Code": "CV"
        },
        {
            "Country": "Central African Republic",
            "Code": "CF"
        },
        {
            "Country": "Chad",
            "Code": "TD"
        },
        {
            "Country": "China",
            "Code": "CN"
        },
        {
            "Country": "Colombia",
            "Code": "CO"
        },
        {
            "Country": "Comoros",
            "Code": "KM"
        },
        {
            "Country": "Congo",
            "Code": "CG"
        },
        {
            "Country": "Congo, Dem. Rep",
            "Code": "CD"
        },
        {
            "Country": "Costa Rica",
            "Code": "CR"
        },
        {
            "Country": "Côte d'Ivoire",
            "Code": "CI"
        },
        {
            "Country": "Cuba",
            "Code": "CU"
        },
        {
            "Country": "Djibouti",
            "Code": "DJ"
        },
        {
            "Country": "Dominica",
            "Code": "DM"
        },
        {
            "Country": "Dominican Republic",
            "Code": "DO"
        },
        {
            "Country": "Ecuador",
            "Code": "EC"
        },
        {
            "Country": "Egypt, Arab Rep.",
            "Code": "EG"
        },
        {
            "Country": "El Salvador",
            "Code": "SV"
        },
        {
            "Country": "Equatorial Guinea",
            "Code": "GQ"
        },
        {
            "Country": "Eritrea",
            "Code": "ER"
        },
        {
            "Country": "Ethiopia",
            "Code": "ET"
        },
        {
            "Country": "Fiji",
            "Code": "FJ"
        },
        {
            "Country": "Gabon",
            "Code": "GA"
        },
        {
            "Country": "Gambia, The",
            "Code": "GM"
        },
        {
            "Country": "Georgia",
            "Code": "GE"
        },
        {
            "Country": "Ghana",
            "Code": "GH"
        },
        {
            "Country": "Grenada",
            "Code": "GD"
        },
        {
            "Country": "Guatemala",
            "Code": "GT"
        },
        {
            "Country": "Guinea",
            "Code": "GN"
        },
        {
            "Country": "Guinea-Bissau",
            "Code": "GW"
        },
        {
            "Country": "Guyana",
            "Code": "GY"
        },
        {
            "Country": "Haiti",
            "Code": "HT"
        },
        {
            "Country": "Honduras",
            "Code": "HN"
        },
        {
            "Country": "India",
            "Code": "IN"
        },
        {
            "Country": "Indonesia",
            "Code": "ID"
        },
        {
            "Country": "Iran, Islamic Rep.",
            "Code": "IR"
        },
        {
            "Country": "Iraq",
            "Code": "IQ"
        },
        {
            "Country": "Jamaica",
            "Code": "JM"
        },
        {
            "Country": "Jordan",
            "Code": "JO"
        },
        {
            "Country": "Kazakhstan",
            "Code": "KZ"
        },
        {
            "Country": "Kenya",
            "Code": "KE"
        },
        {
            "Country": "Kiribati",
            "Code": "KI"
        },
        {
            "Country": "Korea, Dem. People's Rep.",
            "Code": "KP"
        },
        {
            "Country": "Kosovo",
            "Code": "XK"
        },
        {
            "Country": "Kyrgyz Republic",
            "Code": "KG"
        },
        {
            "Country": "Lao PDR",
            "Code": "LA"
        },
        {
            "Country": "Lebanon",
            "Code": "LB"
        },
        {
            "Country": "Lesotho",
            "Code": "LS"
        },
        {
            "Country": "Liberia",
            "Code": "LR"
        },
        {
            "Country": "Libya",
            "Code": "LY"
        },
        {
            "Country": "Macedonia, FYR",
            "Code": "MK"
        },
        {
            "Country": "Madagascar",
            "Code": "MG"
        },
        {
            "Country": "Malawi",
            "Code": "MW"
        },
        {
            "Country": "Malaysia",
            "Code": "MY"
        },
        {
            "Country": "Maldives",
            "Code": "MV"
        },
        {
            "Country": "Mali",
            "Code": "ML"
        },
        {
            "Country": "Marshall Islands",
            "Code": "MH"
        },
        {
            "Country": "Mauritania",
            "Code": "MR"
        },
        {
            "Country": "Mauritius",
            "Code": "MU"
        },
        {
            "Country": "Mexico",
            "Code": "MX"
        },
        {
            "Country": "Micronesia, Fed. Sts.",
            "Code": "FM"
        },
        {
            "Country": "Moldova",
            "Code": "MD"
        },
        {
            "Country": "Mongolia",
            "Code": "MN"
        },
        {
            "Country": "Montenegro",
            "Code": "ME"
        },
        {
            "Country": "Morocco",
            "Code": "MA"
        },
        {
            "Country": "Mozambique",
            "Code": "MZ"
        },
        {
            "Country": "Myanmar",
            "Code": "MM"
        },
        {
            "Country": "Namibia",
            "Code": "NA"
        },
        {
            "Country": "Nauru",
            "Code": "NR"
        },
        {
            "Country": "Nepal",
            "Code": "NP"
        },
        {
            "Country": "Nicaragua",
            "Code": "NI"
        },
        {
            "Country": "Niger",
            "Code": "NE"
        },
        {
            "Country": "Nigeria",
            "Code": "NG"
        },
        {
            "Country": "Pakistan",
            "Code": "PK"
        },
        {
            "Country": "Papua New Guinea",
            "Code": "PG"
        },
        {
            "Country": "Paraguay",
            "Code": "PY"
        },
        {
            "Country": "Peru",
            "Code": "PE"
        },
        {
            "Country": "Philippines",
            "Code": "PH"
        },
        {
            "Country": "Romania",
            "Code": "RO"
        },
        {
            "Country": "Russian Federation",
            "Code": "RU"
        },
        {
            "Country": "Rwanda",
            "Code": "RW"
        },
        {
            "Country": "Samoa",
            "Code": "WS"
        },
        {
            "Country": "São Tomé and Principe",
            "Code": "ST"
        },
        {
            "Country": "Senegal",
            "Code": "SN"
        },
        {
            "Country": "Serbia",
            "Code": "RS"
        },
        {
            "Country": "Sierra Leone",
            "Code": "SL"
        },
        {
            "Country": "Solomon Islands",
            "Code": "SB"
        },
        {
            "Country": "Somalia",
            "Code": "SO"
        },
        {
            "Country": "South Africa",
            "Code": "ZA"
        },
        {
            "Country": "South Sudan",
            "Code": "SS"
        },
        {
            "Country": "Sri Lanka",
            "Code": "LK"
        },
        {
            "Country": "St. Lucia",
            "Code": "LC"
        },
        {
            "Country": "St. Vincent and the Grenadines",
            "Code": "VC"
        },
        {
            "Country": "Sudan",
            "Code": "SD"
        },
        {
            "Country": "Suriname",
            "Code": "SR"
        },
        {
            "Country": "Swaziland",
            "Code": "SZ"
        },
        {
            "Country": "Syrian Arab Republic",
            "Code": "SY"
        },
        {
            "Country": "Tajikistan",
            "Code": "TJ"
        },
        {
            "Country": "Thailand",
            "Code": "TH"
        },
        {
            "Country": "Timor-Leste",
            "Code": "TL"
        },
        {
            "Country": "Togo",
            "Code": "TG"
        },
        {
            "Country": "Tonga",
            "Code": "TO"
        },
        {
            "Country": "Tunisia",
            "Code": "TN"
        },
        {
            "Country": "Turkey",
            "Code": "TR"
        },
        {
            "Country": "Turkmenistan",
            "Code": "TM"
        },
        {
            "Country": "Tuvalu",
            "Code": "TV"
        },
        {
            "Country": "Uganda",
            "Code": "UG"
        },
        {
            "Country": "Ukraine",
            "Code": "UA"
        },
        {
            "Country": "United Republic of Tanzania",
            "Code": "TZ"
        },
        {
            "Country": "Uzbekistan",
            "Code": "UZ"
        },
        {
            "Country": "Vanuatu",
            "Code": "VU"
        },
        {
            "Country": "Venezuela",
            "Code": "VE"
        },
        {
            "Country": "RB",
            "Code": "VE"
        },
        {
            "Country": "Vietnam",
            "Code": "VN"
        },
        {
            "Country": "West Bank",
            "Code": ""
        },
        {
            "Country": "Yemen, Rep.",
            "Code": "YE"
        },
        {
            "Country": "Zambia",
            "Code": "ZM"
        },
        {
            "Country": "Zimbabwe",
            "Code": "ZW"
        }
    ]
};


export const IAScountryCodes = {
    "en-gb": [
        {"Country": "Afghanistan", "Code": "AF"},
        {"Country": "Albania", "Code": "AL"},
        {"Country": "Algeria", "Code": "DZ"},
        {"Country": "Andorra", "Code": "AD"},
        {"Country": "Angola", "Code": "AO"},
        {"Country": "Antigua and Barbuda", "Code": "AG"},
        {"Country": "Argentina", "Code": "AR"},
        {"Country": "Armenia", "Code": "AM"},
        {"Country": "Australia", "Code": "AU"},
        {"Country": "Austria", "Code": "AT"},
        {"Country": "Azerbaijan", "Code": "AZ"},
        {"Country": "Bahamas, the", "Code": "BS"},
        {"Country": "Bahrain", "Code": "BH"},
        {"Country": "Bangladesh", "Code": "BD"},
        {"Country": "Barbados", "Code": "BB"},
        {"Country": "Belarus", "Code": "BY"},
        {"Country": "Belgium", "Code": "BE"},
        {"Country": "Belize", "Code": "BZ"},
        {"Country": "Benin", "Code": "BJ"},
        {"Country": "Bhutan", "Code": "BT"},
        {"Country": "Bolivia, Plurinational State of", "Code": "BO"},
        {"Country": "Bosnia and Herzegovina", "Code": "BA"},
        {"Country": "Botswana", "Code": "BW"},
        {"Country": "Brazil", "Code": "BR"},
        {"Country": "Brunei Darussalam", "Code": "BN"},
        {"Country": "Bulgaria", "Code": "BG"},
        {"Country": "Burkina Faso", "Code": "BF"},
        {"Country": "Burundi", "Code": "BI"},
        {"Country": "Cabo Verde", "Code": "CV"},
        {"Country": "Cambodia", "Code": "KH"},
        {"Country": "Cameroon", "Code": "CM"},
        {"Country": "Canada", "Code": "CA"},
        {"Country": "Central African Republic, the", "Code": "CF"},
        {"Country": "Chad", "Code": "TD"},
        {"Country": "Chile", "Code": "CL"},
        {"Country": "China", "Code": "CN"},
        {"Country": "Colombia", "Code": "CO"},
        {"Country": "Comoros, the", "Code": "KM"},
        {"Country": "Congo, the", "Code": "CG"},
        {"Country": "Congo, The Democratic Republic of the", "Code": "CD"},
        {"Country": "Costa Rica", "Code": "CR"},
        {"Country": "Côte d'Ivoire", "Code": "CI"},
        {"Country": "Croatia", "Code": "HR"},
        {"Country": "Cuba", "Code": "CU"},
        {"Country": "Cyprus", "Code": "CY"},
        {"Country": "Czechia", "Code": "CZ"},
        {"Country": "Denmark", "Code": "DK"},
        {"Country": "Djibouti", "Code": "DJ"},
        {"Country": "Dominica", "Code": "DM"},
        {"Country": "Dominican Republic, the", "Code": "DO"},
        {"Country": "Ecuador", "Code": "EC"},
        {"Country": "Egypt", "Code": "EG"},
        {"Country": "El Salvador", "Code": "SV"},
        {"Country": "Equatorial Guinea", "Code": "GQ"},
        {"Country": "Eritrea", "Code": "ER"},
        {"Country": "Estonia", "Code": "EE"},
        {"Country": "Eswatini", "Code": "SZ"},
        {"Country": "Ethiopia", "Code": "ET"},
        {"Country": "Fiji", "Code": "FJ"},
        {"Country": "Finland", "Code": "FI"},
        {"Country": "France", "Code": "FR"},
        {"Country": "Gabon", "Code": "GA"},
        {"Country": "Gambia, the", "Code": "GM"},
        {"Country": "Georgia", "Code": "GE"},
        {"Country": "Germany", "Code": "DE"},
        {"Country": "Ghana", "Code": "GH"},
        {"Country": "Greece", "Code": "GR"},
        {"Country": "Grenada", "Code": "GD"},
        {"Country": "Guatemala", "Code": "GT"},
        {"Country": "Guinea", "Code": "GN"},
        {"Country": "Guinea-Bissau", "Code": "GW"},
        {"Country": "Guyana", "Code": "GY"},
        {"Country": "Haiti", "Code": "HT"},
        {"Country": "Holy See (Vatican City State)", "Code": "VA"},
        {"Country": "Honduras", "Code": "HN"},
        {"Country": "Hong Kong, SAR of China", "Code": "HK"},
        {"Country": "Hungary", "Code": "HU"},
        {"Country": "Iceland", "Code": "IS"},
        {"Country": "India", "Code": "IN"},
        {"Country": "Indonesia", "Code": "ID"},
        {"Country": "Iran, Islamic Republic of", "Code": "IR"},
        {"Country": "Iraq", "Code": "IQ"},
        {"Country": "Ireland", "Code": "IE"},
        {"Country": "Israel", "Code": "IL"},
        {"Country": "Italy", "Code": "IT"},
        {"Country": "Jamaica", "Code": "JM"},
        {"Country": "Japan", "Code": "JP"},
        {"Country": "Jordan", "Code": "JO"},
        {"Country": "Kazakhstan", "Code": "KZ"},
        {"Country": "Kenya", "Code": "KE"},
        {"Country": "Kiribati", "Code": "KI"},
        {"Country": "Korea, the Democratic People's Republic of", "Code": "KP"},
        {"Country": "Korea, the Republic of", "Code": "KR"},
        {"Country": "Kosovo", "Code": "KV"},
        {"Country": "Kuwait", "Code": "KW"},
        {"Country": "Kyrgyzstan", "Code": "KG"},
        {"Country": "Lao People's Democratic Republic, the", "Code": "LA"},
        {"Country": "Latvia", "Code": "LV"},
        {"Country": "Lebanon", "Code": "LB"},
        {"Country": "Lesotho", "Code": "LS"},
        {"Country": "Liberia", "Code": "LR"},
        {"Country": "Libya", "Code": "LY"},
        {"Country": "Liechtenstein", "Code": "LI"},
        {"Country": "Lithuania", "Code": "LT"},
        {"Country": "Luxembourg", "Code": "LU"},
        {"Country": "Macao, SAR of China", "Code": "MO"},
        {"Country": "Madagascar", "Code": "MG"},
        {"Country": "Malawi", "Code": "MW"},
        {"Country": "Malaysia", "Code": "MY"},
        {"Country": "Maldives", "Code": "MV"},
        {"Country": "Mali", "Code": "ML"},
        {"Country": "Malta", "Code": "MT"},
        {"Country": "Marshall Islands, the", "Code": "MH"},
        {"Country": "Mauritania", "Code": "MR"},
        {"Country": "Mauritius", "Code": "MU"},
        {"Country": "Mexico", "Code": "MX"},
        {"Country": "Micronesia, Federated States of", "Code": "FM"},
        {"Country": "Moldova, the Republic of", "Code": "MD"},
        {"Country": "Monaco", "Code": "MC"},
        {"Country": "Mongolia", "Code": "MN"},
        {"Country": "Montenegro", "Code": "ME"},
        {"Country": "Morocco", "Code": "MA"},
        {"Country": "Mozambique", "Code": "MZ"},
        {"Country": "Myanmar", "Code": "MM"},
        {"Country": "Namibia", "Code": "NA"},
        {"Country": "Nauru", "Code": "NR"},
        {"Country": "Nepal", "Code": "NP"},
        {"Country": "Netherlands, the", "Code": "NL"},
        {"Country": "New Zealand", "Code": "NZ"},
        {"Country": "Nicaragua", "Code": "NI"},
        {"Country": "Niger, the", "Code": "NE"},
        {"Country": "Nigeria", "Code": "NG"},
        {"Country": "North Macedonia", "Code": "MK"},
        {"Country": "Norway", "Code": "NO"},
        {"Country": "Oman", "Code": "OM"},
        {"Country": "Pakistan", "Code": "PK"},
        {"Country": "Palau", "Code": "PW"},
        {"Country": "Palestine, The State of", "Code": "PS"},
        {"Country": "Panama", "Code": "PA"},
        {"Country": "Papua New Guinea", "Code": "PG"},
        {"Country": "Paraguay", "Code": "PY"},
        {"Country": "Peru", "Code": "PE"},
        {"Country": "Philippines, the", "Code": "PH"},
        {"Country": "Poland", "Code": "PL"},
        {"Country": "Portugal", "Code": "PT"},
        {"Country": "Qatar", "Code": "QA"},
        {"Country": "Romania", "Code": "RO"},
        {"Country": "Russian Federation, the", "Code": "RU"},
        {"Country": "Rwanda", "Code": "RW"},
        {"Country": "Saint Kitts and Nevis", "Code": "KN"},
        {"Country": "Saint Lucia", "Code": "LC"},
        {"Country": "Saint Vincent and the Grenadines", "Code": "VC"},
        {"Country": "Samoa", "Code": "WS"},
        {"Country": "San Marino", "Code": "SM"},
        {"Country": "Sao Tome and Principe", "Code": "ST"},
        {"Country": "Saudi Arabia", "Code": "SA"},
        {"Country": "Senegal", "Code": "SN"},
        {"Country": "Serbia", "Code": "RS"},
        {"Country": "Seychelles", "Code": "SC"},
        {"Country": "Sierra Leone", "Code": "SL"},
        {"Country": "Singapore", "Code": "SG"},
        {"Country": "Slovakia", "Code": "SK"},
        {"Country": "Slovenia", "Code": "SI"},
        {"Country": "Solomon Islands", "Code": "SB"},
        {"Country": "Somalia", "Code": "SO"},
        {"Country": "South Africa", "Code": "ZA"},
        {"Country": "South Sudan", "Code": "SS"},
        {"Country": "Spain", "Code": "ES"},
        {"Country": "Sri Lanka", "Code": "LK"},
        {"Country": "Sudan, the", "Code": "SD"},
        {"Country": "Suriname", "Code": "SR"},
        {"Country": "Sweden", "Code": "SE"},
        {"Country": "Switzerland", "Code": "CH"},
        {"Country": "Syrian Arab Republic, the", "Code": "SY"},
        {"Country": "Taiwan, Province of China", "Code": "TW"},
        {"Country": "Tajikistan", "Code": "TJ"},
        {"Country": "Tanzania, The United Republic of", "Code": "TZ"},
        {"Country": "Thailand", "Code": "TH"},
        {"Country": "Timor-Leste", "Code": "TL"},
        {"Country": "Togo", "Code": "TG"},
        {"Country": "Tonga", "Code": "TO"},
        {"Country": "Trinidad and Tobago", "Code": "TT"},
        {"Country": "Tunisia", "Code": "TN"},
        {"Country": "Türkiye", "Code": "TR"},
        {"Country": "Turkmenistan", "Code": "TM"},
        {"Country": "Tuvalu", "Code": "TV"},
        {"Country": "Uganda", "Code": "UG"},
        {"Country": "Ukraine", "Code": "UA"},
        {"Country": "United Arab Emirates, the", "Code": "AE"},
        {"Country": "United Kingdom", "Code": "GB"},
        {"Country": "United States", "Code": "US"},
        {"Country": "Uruguay", "Code": "UY"},
        {"Country": "Uzbekistan", "Code": "UZ"},
        {"Country": "Vanuatu", "Code": "VU"},
        {"Country": "Venezuela, Bolivarian Republic of", "Code": "VE"},
        {"Country": "Viet Nam", "Code": "VN"},
        {"Country": "Yemen", "Code": "YE"},
        {"Country": "Zambia", "Code": "ZM"},
        {"Country": "Zimbabwe", "Code": "ZW"}
    ]
}
