import * as React from "react";
import parse from "html-react-parser";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import { remove } from "aws-amplify/storage";
import { useForm } from "../reducers";
// import {v4 as uuidv4} from 'uuid';
import { Button, Divider, Flex, Text } from "@aws-amplify/ui-react";
import { useState } from "react";

export default function FileUpload(props) {
  const maxFileCount = JSON.parse(props.attributes)?.maxFileCount || 1;
  const { submissions, submissionId } = useForm();
  let submission = submissions.find(
    (submission) => submission.id === submissionId
  );
  let files = submission.files || [];

  const isDisabled =
    files.filter((file) => file.dataKey === props.dataKey).length >=
    maxFileCount;

  const [forceUpdate, setForceUpdate] = useState(0); // Initialize a state variable

  const handleRemoveFile = async (fileKey) => {
    console.log(fileKey)
    // let fileList = await list({});
    // fileList = fileList.items;
    remove({ key: fileKey });
    files = files.filter(file => file.fileKey !== fileKey);
/*    files = files.filter(
      (file) =>
        !fileList.some((fileListItem) => fileListItem.key === file.fileKey)
    );*/
    console.log(files)
    props.handleFileList(files);
    setForceUpdate((forceUpdate) => forceUpdate + 1); // Increment the state variable to force re-render
  };


  const processFile = async ({ file }) => {
    const fileExtension = file.name.split(".").pop();

    // const id = uuidv4();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest("SHA-1", filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, "0"))
          .join("");
        const fileKey = `${hashHex}.${fileExtension}`;

        const newFile = {
          id: hashHex,
          dataKey: props.dataKey,
          fileKey: fileKey,
          hash: hashHex,
          uploaded: new Date().toISOString(),
          size: file.size,
          name: file.name,
        };

          // Ensure no existing file has the same hash and dataKey.
          const isDuplicate = files.some(f => f.dataKey === newFile.dataKey && f.hash === newFile.hash);
          if (!isDuplicate) {
              files = [...files, newFile];
          }

        return { file, key: fileKey };
      });
  };

  return (
    <>
      <label htmlFor={props.dataKey}>{parse(props.label)}</label>
      <StorageManager
        id={props.dataKey}
        key={forceUpdate}
        acceptedFileTypes={["*"]}
        accessLevel="guest"
        maxFileCount={maxFileCount}
        processFile={processFile}
        isResumable
        // onUploadStart={({ key }) => {
        //   console.log(key);
        // }}
        onUploadSuccess={({ key }) => {
          const object = files.find((obj) => obj.id === key.split(".")[0]);
          if (object) {
            props.handleFileList(files);
          } else {
            console.log("Object with the specified ID not found");
          }
        }}
        onUploadError={(error, { key }) => {
          console.log("error", key, error);
        }}
        components={{
          DropZone({ children, inDropZone, ...rest }) {
            if (isDisabled) {
              return null;
            }

            return (
              <Flex
                alignItems="center"
                direction="column"
                padding="medium"
                backgroundColor={inDropZone ? "brand.primary.10" : ""}
                {...rest}
              >
                <Text>Drop files here</Text>
                <Divider size="small" label="or" maxWidth="10rem" />
                {children}
                <Text>
                  {
                    files.filter((file) => file.dataKey === props.dataKey)
                      .length
                  }{" "}
                  / {maxFileCount} files uploaded
                </Text>
              </Flex>
            );
          },
          FilePicker({ onClick }) {
            return (
              <Button
                variation="primary"
                onClick={onClick}
                disabled={isDisabled}
              >
                Browse Files
              </Button>
            );
          },
          FileListHeader() {
            return <></>;
          },
          FileList() {
       /*     console.log(onCancelUpload);
            console.log(onDeleteUpload);
             console.log(files);*/
            return <></>;
          },
        }}
      />
      <Flex direction="column" padding="small">
        {files
          .filter((file) => file.dataKey === props.dataKey)
          .map((file) => (
            <Flex key={file.hash} alignItems="center">
              <Text>{file.name}</Text>
              <Button
                onClick={() => handleRemoveFile(file.fileKey)}
                marginLeft="auto"
              >
                Remove
              </Button>
            </Flex>
          ))}
      </Flex>
    </>
  );
}
