import React, {useReducer, useContext, createContext} from "react";
import PropTypes from 'prop-types';
import uiDictionary from "./assets/uiDictionary.js";

export const FormContext = createContext(null);
const FormDispatchContext = createContext(null);

const initialData = {
    form: {
        meta: {name: 'Welcome', logo: ''},
        pages: [{
            title: '', navigation: null, header: null, footer: null,
            controls: []
        }]
    },
    submissions: [],
    submissionId: null,
    pageNumber: 0,
    numberOfInvalidControls: 0,
    status: [[]],
    currentUser: {},
    language: 'en'
};

const actionHandlers = {
    'SET_CURRENT_USER': (state, value) => (value !== state.currentUser ? {...state, currentUser: value} : state),
    'SET_PAGE_NUMBER': (state, value) => (value !== state.pageNumber ? {...state, pageNumber: value} : state),
    'SET_NO_OF_INVALID_CONTROLS': (state, value) => (value !== state.numberOfInvalidControls ? {
        ...state,
        numberOfInvalidControls: value
    } : state),
    'CHANGE_FORM': (state, value) => (value !== state.form ? {...state, form: value} : state),
    'LOAD_SUBMISSIONS': (state, value) => (value !== state.submissions ? {...state, submissions: value} : state),
    'SET_SUBMISSION_ID': (state, value) => (value !== state.submissionId ? {...state, submissionId: value} : state),
    'SET_STATUS': (state, value) => (value !== state.status ? {...state, status: value} : state),
    'SET_LANGUAGE': (state, value) => (value !== state.language ? {...state, language: value} : state),
    'SET_TOKEN': (state, value) => (value !== state.token ? {...state, token: value} : state),
    'SET_SUBMISSION_COMPLETE': (state, value) => (value !== state.complete ? {...state, complete: value} : state)
};

const formReducer = (state, action) => {
    // console.log('action:', action.type, action.value)
    const handler = actionHandlers[action.type];
    if (handler) {
        // console.log(state, action.value)
        return handler(state, action.value);
    } else {
        throw Error(`Unknown action: ${action.type}, value=${action.value}`);
    }
};


export function FormProvider({children}) {
    const [form, dispatch] = useReducer(
        formReducer,
        initialData
    );

    // Translate function for UI which has access to uiDictionary
    const translateUI = (key) => {
        const translationKeys = uiDictionary[form.language];
        return translationKeys && translationKeys[key.toLowerCase()] ? translationKeys[key.toLowerCase()] : key;
    };

    // Translate function for form, which needs to be supplied with a dictionary
    const translate = (key, formDictionary) => {
        const translationKeys = formDictionary[form.language];
        return translationKeys && translationKeys[key.toLowerCase()] ? translationKeys[key.toLowerCase()] : key;
    };

    const contextValue = {
        ...form,
        translateUI,
        translate,
    };

    return (
        <FormContext.Provider value={contextValue}>
            <FormDispatchContext.Provider value={dispatch}>
                {children}
            </FormDispatchContext.Provider>
        </FormContext.Provider>
    );
}

FormProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export function useForm() {
    return useContext(FormContext);
}

export function useFormDispatch() {
    return useContext(FormDispatchContext);
}
