import React, {useState, useEffect, useMemo} from "react";
import {useForm, useFormDispatch} from "../reducers";
import {useAlert} from "../alertContext";
import parse from "html-react-parser";

import {
    Select,
    Radio,
    RadioGroup,
    TextareaAutosize,
    TextField,
    useTheme,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormLabel,
    Checkbox,
    FormGroup,
    Box,
    Card,
    CardContent,
    Fab,
    Zoom,
    Badge,
} from "@mui/material";
import {
    countryCodes as defaultCountryCodes,
    IASworkCountryCodes,
    IAScountryCodes
} from "../assets/countryCodes";
import {DataStore} from "@aws-amplify/datastore";
import {Submissions} from "../models";
import SubmissionList from "../controls/submissionList";
import Summary from "../controls/summary";
import FileUpload from "../controls/fileUpload";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VerifiedIcon from "@mui/icons-material/Verified";
import AsteriskIcon from "../assets/asterisk"

export default function Main() {
    //TODO - obtain user language
    const UIlanguage = "en-gb";
    const theme = useTheme();
    const dispatch = useFormDispatch();

    const {form, submissions, submissionId, pageNumber, complete} = useForm();
    const page = form.pages[pageNumber];
    let submission = submissions.find((item) => item.id === submissionId);

    const {triggerAlert} = useAlert();

    const controls = useMemo(() => {
        return page.controls || [];
    }, [page.controls]);

    // handle scrolling back to top...
    function throttle(func, delay) {
        let lastCall = 0;
        return function (...args) {
            const now = new Date().getTime();
            if (now - lastCall > delay) {
                lastCall = now;
                func(...args);
            }
        };
    }

    /*
      -----------------------------------
      Scroll position
      */

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = useMemo(
        () =>
            throttle(() => {
                const position = document.getElementById("main-content").scrollTop;
                setScrollPosition((currentPosition) =>
                    currentPosition !== position ? position : currentPosition
                );
            }, 100),
        [setScrollPosition]
    );

    useEffect(() => {
        const contentContainer = document.getElementById("main-content");
        if (contentContainer) {
            contentContainer.addEventListener("scroll", handleScroll);

            // Cleanup function to remove the event listener
            return () => contentContainer.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]); // handleScroll is stable thanks to useCallback, so it won't cause the effect to rerun unnecessarily

    const handleScrollTopClick = (behavior) => {
        const anchor = document.getElementById("back-to-top-anchor");
        if (anchor) {
            // Conditionally set the behavior option based on the provided argument
            const scrollOptions = {block: "start"};
            if (behavior === "smooth") {
                scrollOptions.behavior = "smooth";
            }
            anchor.scrollIntoView(scrollOptions);
        }
    };

    // detect page change
    useEffect(() => {
        handleScrollTopClick("instant");
    }, [pageNumber]);

    /*
      -----------------------------------
      Events
      */


    /*
      -----------------------------------
      Lists
      */
    const countryList = useMemo(() => {
        const list =
            defaultCountryCodes[UIlanguage]?.sort((a, b) =>
                a.Country > b.Country ? 1 : -1
            ) || [];
        return list.map((language) => ({
            name: language.Country,
            value: language.Country,
        }));
    }, [UIlanguage]);

    const IAScountryList = useMemo(() => {
        const list =
            IAScountryCodes[UIlanguage]?.sort((a, b) =>
                a.Country > b.Country ? 1 : -1
            ) || [];
        return list.map((language) => ({
            name: language.Country,
            value: language.Country,
        }));
    }, [UIlanguage]);

    const IASworkCountryList = useMemo(() => {
        const list =
            IASworkCountryCodes[UIlanguage]?.sort((a, b) =>
                a.Country > b.Country ? 1 : -1
            ) || [];
        return list.map((language) => ({
            name: language.Country,
            value: language.Country,
        }));
    }, [UIlanguage]);

    /*
      ----------------------------------------------------------------------
      Completion
      */

    useEffect(() => {
        if (complete) {
            console.log('complete', complete);
            DataStore.save(
                Submissions.copyOf(submission, (updatedSubmission) => {
                    updatedSubmission.meta.complete = true;
                })
            )
        }
    }, [complete]);

    /*
      ----------------------------------------------------------------------
      Values and validation
      */

    // Initial state
    const [controlStates, setControlStates] = useState({});

    const numberOfInvalidControls = useMemo(() => {
        return Object.values(controlStates).filter(
            (control) => control.isValid === false
        ).length;
    }, [controlStates]);

    /*    const percentComplete = useMemo(() => {
            let requiredCount = 0;
            let totalInvalidControls = 0;
            let n=0
            form.pages.forEach(page => {
                requiredCount += page.controls.filter(control => control.required).length;
                page.controls.forEach(control => {
                    if (control.required) {
                        console.log(n++, control.key, submission?.data[control.key], !!submission?.data[control.key]);
                        if (!!submission?.data[control.key]) {
                            totalInvalidControls ++;
                            console.log (totalInvalidControls)
                        }
                    }
                });
            }, 0);
            console.log(requiredCount, totalInvalidControls);
            return Math.round((requiredCount - totalInvalidControls) / requiredCount * 100)
        }, [controlStates]);*/

    function calculatePercentComplete(submission, form) {
        let requiredCount = 0;
        let totalInvalidControls = 0;

        form.pages.forEach(page => {
            requiredCount += page.controls.filter(control => control.required).length;
            page.controls.forEach(control => {
                if (control.required) {
                    if (!!submission?.data[control.key]) {
                        totalInvalidControls++;
                    }
                }
            });
        });
        return Math.round(100 - (requiredCount - totalInvalidControls) / requiredCount * 100);
    }

    // Initialize control states with form values from submission
    useEffect(() => {
        const initialStates = controls.reduce((acc, control) => {
            // Use existing submission data if available, else default to an empty string or other default value
            const existingValue = submission?.data[control.key] ?? "";
            let maxWords = null;
            try {
                maxWords = JSON.parse(control.attributes).maxWords
            } catch (e) {
                //
            }
            if (control.key) {
                let words = [];
                if (typeof existingValue === "string") {
                    words = existingValue.match(/\S+/g) || []; // Match non-space characters to count words
                }
                const wordCount = words.length;
                acc[control.key] = {
                    value: existingValue,
                    isValid: isValidValue(existingValue, !!control.required), // Implement your validation logic here
                    isHidden: false, // Visibility stub, assumed visible initially
                    required: !!control.required,
                    count: wordCount,
                    maxWords: maxWords
                };
            }
            return acc;
        }, {});
        setControlStates(initialStates);
    }, [controls]);

    function isValidValue(value, required) {
        // Implement validation logic here...
        return required && !!value;
    }

    useEffect(() => {
        dispatch({
            type: "SET_NO_OF_INVALID_CONTROLS",
            value: numberOfInvalidControls,
        });

        if (numberOfInvalidControls) {
            triggerAlert({
                message: `${numberOfInvalidControls} required questions not answered`,
                severity: "error",
                vertical: "bottom",
                horizontal: "center",
            });
        }
    }, [controlStates]);

    async function handleInputChange(key, value, save) {
        let saveData = save ?? true;

        //TODO - state history, including timestamp

        // Update the submission data
        const updatedData = {
            ...submission.data,
            [key]: value,
        };

        const currentControl = controls.find((c) => c["key"] === key);

        let count = 0;
        if (typeof value === "string") {
            const limit = controlStates[key].maxWords ?? 0;
            if (limit) {
                let inWord = false; // Track whether we're currently inside a word
                let cutOffIndex = value.length; // Default to the full length of the text

                for (let i = 0; i < value.length; i++) {
                    if (/\S/.test(value[i])) { // Check if the character is not a space
                        if (!inWord) { // We're entering a new word
                            count++; // Increment word count
                            inWord = true; // We are now inside a word
                            if (count > limit) { // If the limit is reached, set cut off point to current position
                                cutOffIndex = i;
                                break; // Exit the loop as we've reached the word limit
                            }
                        }
                    } else {
                        inWord = false; // We've hit a space, so we're no longer in a word
                    }
                }
                // Truncate the text at the last valid position that does not exceed the word limit
                value = value.substring(0, cutOffIndex);
            }
        }

        /*

                setControlStates((prevStates) => ({
                    ...prevStates,
                    [key]: {
                        ...prevStates[key],
                        value: value,
                        isValid: isValidValue(value, !!currentControl?.required), // Implement your validation logic here
                        count: count
                    },
                }));
        */
        function updateControlStates(key, value, count, currentControl) {
            return new Promise(resolve => {
                setControlStates(prevStates => {
                    const newState = {
                        ...prevStates,
                        [key]: {
                            ...prevStates[key],
                            value: value,
                            isValid: isValidValue(value, !!currentControl?.required), // Your validation logic
                            count: count
                        },
                    };

                    resolve(newState);
                    return newState;
                });
            });
        }

        async function blockingUpdate() {
            // some code
            await updateControlStates(key, value, count, currentControl);
            // Save the updated submission back to DataStore
            if (saveData) {

                await DataStore.save(
                    Submissions.copyOf(submission, (updatedSubmission) => {
                        updatedSubmission.data = updatedData;
                        updatedSubmission.meta.page = pageNumber;
                        // page number will be saved when data changes, otherwise it doesn't matter
                        updatedSubmission.meta.percentComplete = calculatePercentComplete(updatedSubmission, form);
                    })
                )
                    .then((r) => {
                        submission = r;
                    })
                    .catch((error) => {
                        triggerAlert({
                            message: `Update failed - ${error.message}`,
                            severity: "error",
                        });
                        console.error(`Failed to update: ${error.message}`);
                    });
            }
        }

        blockingUpdate()

    }

    async function handleFileList(files) {
        console.log("handleFileList", files);

        // Initialize a new data object or use existing data from submission if available
        let newData = submission.data ? {...submission.data} : {};

        // Determine added and removed files
        const addedFiles = submission.files
            ? files.filter((file) => !submission.files.some((f) => f.dataKey === file.dataKey))
            : files;

        const removedFiles = submission.files
            ? submission.files.filter((file) => !files.some((f) => f.dataKey === file.dataKey))
            : [];

        // Set data[dataKey] to true for added files
        addedFiles.forEach((file) => {
            const dataKey = file.dataKey;
            if (dataKey) {
                newData[dataKey] = true;
            }
        });

        // Set data[dataKey] to false for removed files
        removedFiles.forEach((file) => {
            const dataKey = file.dataKey;
            if (dataKey in newData) {
                newData[dataKey] = false;
            }
        });

        function deduplicateFilesWithMap(files) {
            const filesMap = new Map();
            for (const file of files) {
                if (!filesMap.has(file.id)) {
                    filesMap.set(file.id, file);
                }
            }
            return Array.from(filesMap.values());
        }

        deduplicateFilesWithMap(files);

        setControlStates((prevStates) => {
            return Object.keys(prevStates).reduce((acc, key) => {
                // Check if this key is related to files.
                const isFileKey = files.some(file => file.dataKey === key);
                // Determine if a file exists for this key.
                const fileExists = isFileKey && files.some(file => file.dataKey === key);

                acc[key] = {
                    ...prevStates[key],
                    value: prevStates[key].value,
                    // If it's a file-related key, use file existence to determine validity.
                    // Otherwise, use the existing validation logic.
                    isValid: isFileKey ? fileExists : isValidValue(prevStates[key].value, !!prevStates[key].required),
                    count: prevStates[key].count
                };
                return acc;
            }, {});
        });

        await DataStore.save(
            Submissions.copyOf(submission, (updatedSubmission) => {
                updatedSubmission.files = files; // Update files list
                updatedSubmission.data = newData; // Update data object
                updatedSubmission.meta.page = pageNumber;
            })
        )
            .then((r) => {
                submission = r; // Update local submission variable with the result

            })
            .catch((error) => {
                triggerAlert({
                    message: `Update failed - ${error.message}`,
                    severity: "error",
                });
                console.error(`Failed to update: ${error.message}`);
            });
    }

    function generateField(control, index, handleInputChange, controlStates, submission) {

        const attributes = JSON.parse(control?.attributes || "{}");
        const label = control?.label ? parse(control.label) : "";
        const key = control.key;
        const required = control.required;
        let elements;

        switch (control.type) {
            case "AFFILIATIONS":
                break;

            case "AUTHORS":
                break;

            case "BUTTON":
                break;

            case "CHECKBOX":
                elements = [];

                // Ensure submission.data[key] has a default value to avoid transitioning from uncontrolled to controlled
                // const checkboxValue = submission?.data[key] !== undefined ? submission.data[key] : false;

                control.options.forEach((o, i) => {
                    const option = JSON.parse(o);
                    elements.push(
                        <div key={index + "-" + i}>
                            <Checkbox
                                name={key}
                                checked={!!controlStates[key]?.value} // Cast to boolean for checkbox
                                onChange={(e) => handleInputChange(key, e.target.checked)}
                            />
                            <label>{parse(option?.name)}</label>
                        </div>
                    );
                });
                return (
                    <>
                        <FormLabel>{label}</FormLabel>
                        <FormGroup>{elements}</FormGroup>
                    </>
                );

            case "DATETIMEPICKER":
                break;

            case "FILE": {
                const {key: dataKey, ...otherProps} = control;
                return (
                    <FileUpload
                        dataKey={dataKey}
                        handleFileList={handleFileList}
                        {...otherProps}
                    />
                );
            }
            case "INFORMATION":
                return <div key={index}>{parse(control?.content)}</div>;

            case "MESSAGE": {
                return null;
            }

            case "RADIO":
                elements = [];
                control.options.forEach((o, i) => {
                    const option = JSON.parse(o);
                    elements.push(
                        <div key={index + "-" + i}>
                            <Radio
                                name={key}
                                onChange={(e) => handleInputChange(key, e.target.value)}
                                value={option.value}
                                checked={option.value === controlStates[key]?.value}
                            ></Radio>
                            <label>{option.name}</label>
                        </div>
                    );
                });
                return (
                    <>
                        <FormLabel>{label}</FormLabel>
                        <RadioGroup>{elements}</RadioGroup>
                    </>
                );

            case "RATING":
                break;

            case "SELECT": {
                //TODO turn this into a custom component

                let options;
                if (control.options.length === 1) {
                    switch (control.options[0]) {
                        case 'IASworkCountryList':
                            options = IASworkCountryList;
                            break;
                        case 'IAScountryList':
                            options = IAScountryList;
                            break;
                        default:
                            options = countryList;
                    }
                    // options = IAScountryList; //Array[JSON.parse(control.options[0])]
                } else {
                    options = control.options.map((item) => JSON.parse(item));
                }

                let value = controlStates[key]?.value;
                if (attributes.multiple) {
                    value = value ? value.split(";") : [];
                } else {
                    value = value ? value : "";
                }

                elements = [];
                options.forEach((option, i) => {
                    let isSelected;
                    if (attributes.multiple) {
                        isSelected = controlStates[key]?.value
                            ? controlStates[key].value.split(";").includes(option.value)
                            : false;
                    } else {
                        isSelected = controlStates[key]?.value === option.value;
                    }

                    // Conditionally render MenuItem with a checkbox only if attributes.multiple is true
                    const menuItemContent = attributes.multiple ? (
                        <FormControlLabel
                            control={<Checkbox checked={isSelected}/>}
                            label={option.name}
                            sx={{width: "100%", justifyContent: "space-between"}}
                        />
                    ) : (
                        option.name
                    ); // When not multiple, just render the option name

                    elements.push(
                        <MenuItem key={index + "-" + i} value={option.value}>
                            {menuItemContent}
                        </MenuItem>
                    );
                });

                return (
                    <>
                        <FormLabel>{label}</FormLabel>
                        <Select
                            sx={{width: 200, height: 30}}
                            multiple={attributes.multiple}
                            autoWidth
                            renderValue={(selected) => {
                                if (Array.isArray(selected)) {
                                    return selected.join("; ");
                                } else {
                                    const selectedOption = options.find(
                                        (option) => option.value === selected
                                    );
                                    return selectedOption ? selectedOption.name : "";
                                }
                            }}
                            value={value}
                            name={key}
                            onChange={(e) => {
                                attributes.multiple
                                    ? handleInputChange(key, e.target.value.join(";"), false)
                                    : handleInputChange(key, e.target.value, false);
                            }}
                            onBlur={(e) => {
                                attributes.multiple
                                    ? handleInputChange(key, e.target.value.join(";"), true)
                                    : handleInputChange(key, e.target.value, true);
                            }}
                        >
                            {elements}
                        </Select>
                    </>
                );
            }
            case "SLIDER":
                break;

            case "SPONSOR":
                break;

            case "SUBMISSIONLIST":
                return <SubmissionList control={control} currentKey={index}/>;

            case "SUMMARY":
                return <Summary control={control} submission={submission}/>;

            case "SWITCH":
                break;

            case "TABLE":
                break;

            case "TEXT": {
                if (attributes?.height >= 1) {
                    return (
                        <>
                            <FormLabel htmlFor={"textarea" + index}>{label}</FormLabel>
                            <TextareaAutosize
                                key={index}
                                id={"textarea" + index}
                                color="primary"
                                minRows={attributes.height}
                                name={key}
                                onBlur={(e) => handleInputChange(key, e.target.value)}
                                onChange={(e) =>
                                    handleInputChange(key, e.target.value, false)
                                }
                                value={controlStates[key]?.value || ""}
                                placeholder={control.options?.placeholder}
                            />
                            {
                                attributes?.maxWords > 0 && (
                                    <div>
                                        {controlStates[key]?.count} words (<em>{attributes.maxWords} limit</em>)
                                    </div>
                                )
                            }
                        </>
                    );
                } else {
                    return (
                        <TextField
                            key={index}
                            label={label}
                            required={required && !controlStates[key]?.isValid}
                            color="primary"
                            variant="outlined"
                            name={key}
                            onBlur={(e) => handleInputChange(key, e.target.value)}
                            onChange={(e) =>
                                handleInputChange(key, e.target.value, false)
                            }
                            value={controlStates[key]?.value || ""}
                            placeholder={control.options?.placeholder}
                        />
                    );
                }
            }

            case "TOGGLE":
                break;
            default:
                return <div key={index}>{control.type}</div>;
        }
    }

    return (
        <Box
            id="main-content"
            sx={{
                overflowY: "auto",
                flexGrow: 1,
                padding: "20px",
            }}
        >
            <div id="back-to-top-anchor"></div>
            <div style={theme.typography.h6}>{page?.title}</div>
            {controls.map((control, index) => {
                // eslint-disable-next-line no-eval
                if (eval(control?.hidden)) {
                    return null;
                }

                const key = control.key;
                const field = generateField(control, index, handleInputChange, controlStates, submission);

                return (
                    <Box
                        key={index + "-box"}
                        sx={{width: "100vw - 20px", padding: "5px 10px 5px 10px"}}
                    >
                        <Badge
                            invisible={!controlStates[key]?.isValid && !control.required} // Show badge only if controlStates[key]?.isValid is true or if control is required and not valid
                            badgeContent={
                                controlStates[key]?.isValid ? (
                                    <VerifiedIcon style={{color: "green"}}/> // Show green tick if controlStates[key]?.isValid is true
                                ) : control.required ? (
                                    <AsteriskIcon style={{color: "red"}}/>
                                ) : null // Show priority icon if controlStates[key]?.isValid is false and required is true, otherwise show nothing
                            }
                            style={{width: "100%"}}
                            sx={{
                                ".MuiBadge-badge": {
                                    transform: "translate(5px, 5px)", // Adjusts the badge position 5px right and 5px down
                                },
                            }}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <Card
                                sx={{width: "100%"}}
                                key={index + "-card"}
                                variant={"outlined"}
                            >
                                <CardContent
                                    key={index + "-content"}
                                    sx={{position: "relative"}}
                                >
                                    <FormControl fullWidth key={index}>
                                        {field}
                                    </FormControl>
                                </CardContent>
                            </Card>
                        </Badge>
                    </Box>
                );
            })}
            <ScrollTop
                scrollPosition={scrollPosition}
                onScrollTopClick={() => handleScrollTopClick("smooth")}
            />
        </Box>
    );
}

const ScrollTop = ({scrollPosition, onScrollTopClick}) => {
    const trigger = scrollPosition > 100;
    return (
        <Zoom in={trigger}>
            <Box
                onClick={onScrollTopClick}
                role="presentation"
                sx={{position: "fixed", bottom: 100, right: 16}}
            >
                <Fab size="small" aria-label="scroll back to top" color="secondary">
                    <KeyboardArrowUpIcon/>
                </Fab>
            </Box>
        </Zoom>
    );
};
