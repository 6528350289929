const uiDictionary = {
    "en": {
        "next": "Next",
        "back": "Back",
        "cancel": "Cancel",
        "sign_in": "Sign In",
        "email": "Email",
        "password": "Password",
        "confirm_password": "Confirm Password",
        "given_name": "Given Name",
        "family_name": "Family Name",
        "forgot_password": "Forgot your password?",
        "create_account": "Create Account"
    },
    "es": {
        "next": "Siguiente",
        "back": "Atrás",
        "cancel": "Cancelar",
        "sign_in": "Iniciar sesión",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "confirm_password": "Confirmar contraseña",
        "given_name": "Nombre",
        "family_name": "Apellido",
        "forgot_password": "¿Olvidaste tu contraseña?",
        "create_account": "Crear cuenta"
    },
    "fr": {
        "next": "Suivant",
        "back": "Retour",
        "cancel": "Annuler",
        "sign_in": "Se connecter",
        "email": "E-mail",
        "password": "Mot de passe",
        "confirm_password": "Confirmer le mot de passe",
        "given_name": "Prénom",
        "family_name": "Nom de famille",
        "forgot_password": "Mot de passe oublié ?",
        "create_account": "Créer un compte"
    },
    "de": {
        "next": "Weiter",
        "back": "Zurück",
        "cancel": "Abbrechen",
        "sign_in": "Anmelden",
        "email": "E-Mail",
        "password": "Passwort",
        "confirm_password": "Passwort bestätigen",
        "given_name": "Vorname",
        "family_name": "Nachname",
        "forgot_password": "Passwort vergessen?",
        "create_account": "Konto erstellen"
    },
    "it": {
        "next": "Avanti",
        "back": "Indietro",
        "cancel": "Annulla",
        "sign_in": "Accedi",
        "email": "Email",
        "password": "Password",
        "confirm_password": "Conferma Password",
        "given_name": "Nome",
        "family_name": "Cognome",
        "forgot_password": "Hai dimenticato la password?",
        "create_account": "Crea un account"
    },
    "pt": {
        "next": "Próximo",
        "back": "Voltar",
        "cancel": "Cancelar",
        "sign_in": "Entrar",
        "email": "E-mail",
        "password": "Senha",
        "confirm_password": "Confirmar senha",
        "given_name": "Nome",
        "family_name": "Sobrenome",
        "forgot_password": "Esqueceu sua senha?",
        "create_account": "Criar conta"
    },
    "ru": {
        "next": "Далее",
        "back": "Назад",
        "cancel": "Отмена",
        "sign_in": "Войти",
        "email": "Электронная почта",
        "password": "Пароль",
        "confirm_password": "Подтвердить пароль",
        "given_name": "Имя",
        "family_name": "Фамилия",
        "forgot_password": "Забыли пароль?",
        "create_account": "Создать аккаунт"
    },
    "zh-cn": {
        "next": "下一步",
        "back": "返回",
        "cancel": "取消",
        "sign_in": "登录",
        "email": "电子邮件",
        "password": "密码",
        "confirm_password": "确认密码",
        "given_name": "名",
        "family_name": "姓",
        "forgot_password": "忘记密码？",
        "create_account": "创建账户"
    },
    "ja": {
        "next": "次へ",
        "back": "戻る",
        "cancel": "キャンセル",
        "sign_in": "サインイン",
        "email": "メール",
        "password": "パスワード",
        "confirm_password": "パスワードを認証する",
        "given_name": "名",
        "family_name": "姓",
        "forgot_password": "パスワードを忘れましたか？",
        "create_account": "アカウントを作成する"
    },
    "ar": {
        "next": "التالي",
        "back": "العودة",
        "cancel": "إلغاء",
        "sign_in": "تسجيل الدخول",
        "email": "البريد الإلكتروني",
        "password": "كلمة المرور",
        "confirm_password": "تأكيد كلمة المرور",
        "given_name": "الاسم الأول",
        "family_name": "اسم العائلة",
        "forgot_password": "هل نسيت كلمة المرور؟",
        "create_account": "إنشاء حساب"
    }
};

export default uiDictionary;
