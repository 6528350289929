import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";

import { DataStore, syncExpression } from "@aws-amplify/datastore";
import {
  Forms,
  Submissions,
  Users,
  Accounts,
  Tokens,
  Projects,
} from "./models";
import "./App.css";

import { useEffect, useState } from "react";
import { useFormDispatch, useForm } from "./reducers";
import { AlertProvider } from "./alertContext";

import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Main from "./components/main.js";
import Navbar from "./components/navbar";
import Events from "./components/events";

import {
  Authenticator,
  SelectField,
  CheckboxField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import amplifyconfig from "./amplifyconfiguration.json";

import { Stack } from "@mui/material";

import { fetchUserAttributes } from "aws-amplify/auth";

import { I18n } from "aws-amplify/utils";

import { translations } from "@aws-amplify/ui-react";

I18n.putVocabularies(translations);

Amplify.configure(amplifyconfig);

/*
Detect if signin or refresh, using useRef

1. On signIn
    start datastore
    get form token from url.split('/')[1]; save it to localstorage
    if(token)
        sync using token

2. Re-render when signed in
    get form token from url or localstorage
    if(!token)
        sync Users
        get token from user data
        ask to resume work on form
    if(token)
       sync Forms and Users

Sign out: stop and clear datastore, clear localstorage
 */

const App = () => {
  const dispatch = useFormDispatch();
  const { form, submissions } = useForm();
  let token;

  useEffect(() => {
    token = window.location.pathname.split("/")[1];
    if (token) {
      localStorage.setItem("HLtoken", token);
    } else {
      token = localStorage.getItem("HLtoken") || "";
      if (token) {
        dispatch({ type: "SET_TOKEN", value: token });
        window.location.href =
          window.location.origin + "/" + localStorage.getItem("HLtoken");
      }
    }
  }, []);

  const urlLanguage = window.location.pathname.split("/")[2];
  // const [language, setLanguage] = useState(urlLanguage || "en");

  // workflow variables
  const [authUser, setAuthUser] = useState(null);

  const [isDataStoreReady, setIsDataStoreReady] = useState(false);

  // workflow hooks...

  useEffect(() => {
    // get authUser on refresh
    const fetchUser = async () => {
      try {
        const authUserAttributes = await fetchUserAttributes();
        setAuthUser(authUserAttributes);
        dispatch({
          type: "SET_LANGUAGE",
          value: urlLanguage || authUserAttributes["custom:language"],
        });
      } catch (error) {
        console.log(error);
        setAuthUser(null);
      }
    };

    // Listen for sign-in and sign-out events
    const listener = Hub.listen("auth", async (data) => {
      const { payload } = data;
      switch (payload.event) {
        case "signedIn":
          console.log("User has signed in", payload.data);
          fetchUser();
          break;
        case "signedOut": {
          console.log("User has signed out");
          const cleanup = async () => {
            setAuthUser(null);
            await DataStore.stop();
            await DataStore.clear();
            localStorage.clear();
            window.location.href = window.location.origin;
          };
          cleanup();
          break;
        }
        default:
          console.log(payload.event, payload.data);
          break;
      }
    });

    fetchUser();

    return () => {
      listener(); // This will remove the listener
    };
  }, [urlLanguage, dispatch]);

  useEffect(() => {
    console.log(token, localStorage.getItem("HLtoken"));
    if (token) {
      localStorage.setItem("HLtoken", token);
    } else {
      if (localStorage.getItem("HLtoken")) {
        window.location.href =
          window.location.origin + "/" + localStorage.getItem("HLtoken");
      }
    }
  }, [token]);

  const changeUserToken = async (currentUser) => {
    // Create a new currentState object with the updated token
    const updatedState = {
      ...currentUser.currentState, // spread operator to copy existing properties
      token, // update the token property
    };

    try {
      // Save the updated user object back to DataStore
      await DataStore.save(
        Users.copyOf(currentUser, (updated) => {
          updated.currentState = updatedState;
        })
      );
    } catch (e) {
      console.error("Error updating user token:", e);
    }
  };

  useEffect(() => {
    if (authUser) {
      if (token) {
        console.log("sync all");
        DataStore.configure({
          syncExpressions: [
            syncExpression(Forms, () => {
              return (form) => form.token.eq(token);
            }),
            syncExpression(Submissions, () => {
              return (submissions) =>
                submissions.and((submissions) => [
                  submissions.userId.eq(authUser.sub),
                  submissions.formToken.eq(token),
                ]);
            }),
            syncExpression(Users, () => {
              return (users) => users.id.eq(authUser.sub);
            }),
            syncExpression(Accounts, () => {
              return (accounts) => accounts.id.eq(0); // A condition that always evaluates to false
            }),
            syncExpression(Tokens, () => {
              return (tokens) => tokens.id.eq(0); // A condition that always evaluates to false
            }),
            syncExpression(Projects, () => {
              return (projects) => projects.id.eq(0); // A condition that always evaluates to false
            }),
          ],
        });
      } else {
        console.log("sync users", authUser);
        DataStore.configure({
          syncExpressions: [
            syncExpression(Forms, () => {
              return (form) => form.token.eq(null);
            }),
            syncExpression(Submissions, () => {
              return (submissions) =>
                submissions.and((submissions) => [
                  submissions.userId.eq(null),
                  submissions.formToken.eq(null),
                ]);
            }),
            syncExpression(Users, () => {
              return (users) => users.id.eq(authUser.sub);
            }),
            syncExpression(Accounts, () => {
              return (accounts) => accounts.id.eq(0); // A condition that always evaluates to false
            }),
            syncExpression(Tokens, () => {
              return (tokens) => tokens.id.eq(0); // A condition that always evaluates to false
            }),
            syncExpression(Projects, () => {
              return (projects) => projects.id.eq(0); // A condition that always evaluates to false
            }),
          ],
        });
        console.log("synced users", authUser);
      }

      // don't start the datastore until logged in
      const startDataStore = async () => {
        //await DataStore.start();
        DataStore.clear()
          .then(() => DataStore.start())
          .catch((err) => console.error("Error starting DataStore:", err));
        console.log("Datastore started");
        setIsDataStoreReady(true);
      };

      startDataStore();

      // re-render after synchronisation
      Hub.listen("datastore", async (hubData) => {
        const { event } = hubData.payload;
        // console.log(event)
        if (event === "ready") {
          DataStore.observeQuery(Forms, (f) => f.token.eq(token), {}).subscribe(
            (snapshot) => {
              const { items, isSynced } = snapshot;
              if (items.length && isSynced) {
                // console.log('form synced')
                dispatch({ type: "CHANGE_FORM", value: items[0] });
              }
            }
          );
          console.log("debug here");
          DataStore.observeQuery(
            Submissions,
            (f) =>
              f.and((f) => [f.userId.eq(authUser.sub), f.formToken.eq(token)]),
            {}
          ).subscribe((snapshot) => {
            const { items, isSynced } = snapshot;
            if (items.length && isSynced) {
              // console.log('submissions synced')
              dispatch({ type: "LOAD_SUBMISSIONS", value: items });
            }
          });
          console.log("debug there");

          DataStore.observeQuery(
            Users,
            (u) => u.id.eq(authUser.sub),
            {}
          ).subscribe((snapshot) => {
            const { items, isSynced } = snapshot;
            if (items.length && isSynced) {
              const currentUser = items[0];
              console.log(items[0]);
              dispatch({ type: "SET_CURRENT_USER", value: currentUser });
              if (currentUser?.currentState?.token !== token) {
                changeUserToken(currentUser);
              }
              if (!token) {
                try {
                  window.location.href =
                    window.location.origin +
                    "/" +
                    currentUser.currentState.token;
                } catch (e) {
                  console.log(e);
                }
              }
            }
          });
        }
      });
    }
  }, [authUser, token]); // eslint-disable-line

  //TODO handle form mutations
  useEffect(() => {
    return () => {
      // console.log('form changed:', form)
    };
  }, [form]);

  //set language options
  let selectedLanguage = urlLanguage || navigator.language || "en";
  selectedLanguage = selectedLanguage === "en-GB" ? "en" : selectedLanguage;
  I18n.setLanguage(selectedLanguage);

  I18n.putVocabularies({
    en: {
      Terms:
        'I agree to the <a href="http://example.com" target="blank">Terms & Conditions</a>',
      AgreeTerms: "You must agree to the Terms & Conditions",
    },
    fr: {
      "Given Name": "Prénom",
      "Family Name": "Nom de famille",
      Language: "Langue",
      Terms:
        'J\'accepte les <a href="http://example.com" target="blank">Conditions Générales</a>',
      AgreeTerms: "Vous devez accepter les Conditions Générales.",
    },
    de: {
      "Given Name": "Vorname",
      "Family Name": "Nachname",
      Language: "Sprache",
      Terms:
        'Ich akzeptiere die <a href="http://example.com" target="blank">Allgemeinen Geschäftsbedingungen</a>',
      AgreeTerms: "Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen.",
    },
    es: {
      "Given Name": "Nombre",
      "Family Name": "Apellido",
      Language: "Idioma",
      Terms:
        'Acepto los <a href="http://example.com" target="blank">Términos y Condiciones</a>',
      AgreeTerms: "Debe aceptar los Términos y Condiciones.",
    },
  });

  const languageOptions = [
    { label: "English", value: "en" },
    { label: "Français", value: "fr" },
    { label: "Deutsch", value: "de" },
    { label: "Español", value: "es" },
  ];

  useEffect(() => {
    // attributes['custom:language'] = urlLanguage || navigator.language || 'en';
    return () => {
      // console.log('form changed:', form)
    };
  }, [form]);

  //TODO handle submission mutations
  useEffect(() => {
    return () => {
      // console.log('submissions changed:', submissions)
    };
  }, [submissions]);

  const handleLanguageChange = (e) => {
    I18n.setLanguage(e.target.value);
  };

  return (
    <AlertProvider>
      <Authenticator
        components={{
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();

              return (
                <>
                  <Authenticator.SignUp.FormFields />
                  <SelectField
                    name="custom:language"
                    label={I18n.get("Language")}
                    defaultValue={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    {languageOptions.map((lang) => (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    ))}
                  </SelectField>

                  <CheckboxField
                    errorMessage={validationErrors.acknowledgement}
                    hasError={!!validationErrors.acknowledgement}
                    name="acknowledgement"
                    value="yes"
                    label={
                      <span
                        dangerouslySetInnerHTML={{ __html: I18n.get("Terms") }}
                      />
                    }
                  />
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
              return {
                acknowledgement: I18n.get("AgreeTerms"),
              };
            }
          },
        }}
      >
        {({ signOut }) => (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Header logout={signOut} />
            {isDataStoreReady ? <Main /> : <div>Loading...</div>}
            <Navbar />
            <Footer />
            <Events />
          </Stack>
        )}
      </Authenticator>
    </AlertProvider>
  );
};

export default App;
