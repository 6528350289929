import React, {createContext, useContext, useState} from 'react';
import AlertBar from "./components/alertBar";

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({children}) => {

    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'info',
    });

    const triggerAlert = ({message, severity, vertical, horizontal}) => {
        setAlert({open: true, message, severity, vertical, horizontal});
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert((prev) => ({...prev, open: false}));
    };

    return (
        <AlertContext.Provider value={{triggerAlert}}>
            {children}
            <AlertBar
                open={alert.open}
                handleClose={handleClose}
                severity={alert.severity}
                message={alert.message}
                vertical={alert.vertical}
                horizontal={alert.horizontal}
            />
        </AlertContext.Provider>
    );
};
